.banner-primary {
  background: #fdfcfa;
  padding-bottom: 30px;
}
.banner-primary-container {
  display: flex;
  max-width: 1440px;
  margin: auto;
}

.banner-primary-section-2 {
  flex: 1;
  margin-top: 40px;
}
.banner-primary-section-1 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-primary-content {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #212325;
  opacity: 0.8;
  margin-top: 28px;
}
.banner-primary-button {
  /* Frame 163 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 11px;

  width: 190px;
  height: 45px;

  /* Primary */
  background: #f5a023;
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  /* Make Schedule to talk */

  width: 150px;
  height: 17px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Sec */
  color: #090909;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 18px;
}
.banner-primary-header {
  font-weight: 400;
  font-size: 36px;
  letter-spacing: 0.045em;
  line-height: 61.99px;
  color: #090909;
}

@media (max-width: 950px) {
  .banner-primary-container {
    flex-direction: column;
    max-height: 934px;
  }
  .banner-primary-section-1-margin {
    margin: 20px !important;
  }
  .banner-primary-header {
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0.02em;
    line-height: 38px;
    color: #090909;
  }
  .banner-primary-section-2 {
    transform: scale(0.9);
    position: relative;
    top: -10px;
    /* left: -20px; */
  }
  .banner-primary-button {
    margin: auto;

    margin-top: 25px;
    padding: 14px 60px;
  }
}

@media (max-width: 600px) {
  .banner-primary-section-2 {
    /* transform: scale(0.5);
    position: relative;
    top: -200px; */
    left: -20px;
  }
}
