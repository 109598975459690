.why-us-header {
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
  margin-bottom: 70px;
  padding-top: 80px;
}

.why-us-container {
  background-color: #fff;
}
.why-us-card-main-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}
.why-us-card-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  text-align: center;
  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.4);
}

.why-us-card-container {
  display: flex;
  flex-direction: column;
  padding: 25px 10px !important;
  max-width: 353px !important;
  background: #ffffff;
}
