.footer {
  background-color: #000;
}
.footer-container {
  background-color: #000;
  padding: 66px;
  padding-bottom: 6px;
  max-width: 1440px;
  margin: auto;
}
.footer-logo-container {
  margin-bottom: 50px;
  margin-left: 10px;
}
.footer-content-container {
  display: flex;
}
.footer-social-link-icons {
  margin: 10px;
  height: 50px;
  width: 50px;
}
.footer-social-icons {
  margin: 10px;
  height: 20px;
  width: 20px;
}
.footer-contentSection-1 {
  flex: 2;
}
.footer-contentSection-2 {
  flex: 1;
}
.footer-contentSection-3 {
  flex: 2;
}
.footer-socialmedia-link-container-1 {
  display: flex;
}
.footer-socialmedia-link-container-2 {
  display: none;
}
.footer-bottom {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #ffffff;
  opacity: 0.8;
  padding: 20px;
}

.footer-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin: 5px;
  margin-bottom: 20px;
}
.footer-links,
.footer-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 5px;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-decoration: none;
}

.footer-reach-us-content-container {
  display: flex;
}

.footer-devider {
  border-bottom: 1px solid #ccc;
  margin-top: 100px;
}

@media (max-width: 768px) {
  .footer-content-container {
    flex-direction: column;
  }
  .footer-socialmedia-link-container-1 {
    display: none;
  }
  .footer-socialmedia-link-container-2 {
    margin-top: 20px;
    display: flex;
  }
  .footer-devider {
    display: none;
  }
}
