.kpi-banner-container {
  display: flex;
  flex-direction: column;
  /* height: 292, */
}

.kpi-card-divider {
  border-right: 1px solid #fff;
  height: 55px;
  margin: 35px;
}
/* style={{ borderRight: "1px solid #fff", height: 55, margin: 35 }} */
@media (max-width: 725px) {
  .kpi-banner-card-container {
    flex-direction: column;
  }
  .kpi-card-divider {
    border-right: 0px solid #fff;
    border-bottom: 1px solid #fff;
    width: 55px;
    margin: 35px;
  }
}
