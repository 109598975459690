body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  background-color: #090909;
}
.header-body {
  height: 93px;
  background-color: #090909;
  padding: 0px 80px;
  display: flex;
  overflow: hidden;
  max-width: 1300px;
  margin: auto;
}

.header-logo-container,
.header-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-link {
  font-weight: 400;
  font-size: 16px;
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  margin: 0 25px;
  cursor: pointer;
  user-select: none;
}

.header-link-active {
  color: #f5a023;
  font-weight: 700;
}

.link-container {
  display: flex;
}

.hamburger-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (min-width: 1024px) {
  .header-body {
    padding: 0px 40px;
  }
  .hamburger-link {
    display: none;
  }
}
@media (max-width: 1024px) {
  .header-body {
    padding: 0px 40px;
  }
  .hamburger-link {
    display: none;
  }
}

@media (max-width: 768px) {
  .header-body {
    padding: 0px 30px;
  }
  .hamburger-link {
    display: none;
  }
  .header-link {
    font-size: 14px;
    margin: 0 15px;
  }
}
@media (max-width: 425px) {
  .header-body {
    padding: 0px 20px;
  }
  .link-container {
    display: none;
  }
  .hamburger-link {
    display: flex;
  }
  .header-link {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .header-body {
    padding: 0px 10px;
  }
  .link-container {
    /* display: none; */
  }
  .hamburger-link {
    display: flex;
  }
  .header-link {
    font-size: 14px;
  }
}
@media (max-width: 1440px) {
  .header-body {
    padding: 0px 70px;
  }
}
@media (max-width: 850px) {
  .header-body {
    padding: 0px 20px;
  }
}
