.testimonial-card {
  background: linear-gradient(
    100.74deg,
    rgba(245, 160, 35, 0.17) -93.25%,
    rgba(245, 160, 35, 0) 88.45%
  );
  border-radius: 6px;
  width: 430px;
  margin: 10px;
  margin-top: 50px;
  /* margin-top: 80px; */
  border-right: 0.5px solid #f5a023;
  border-bottom: 0.5px solid #f5a023;
  cursor: pointer;
}

.testimonial-card > li {
  margin-top: 20px;
  list-style: none;
  text-align: center;
}

.jhon-image {
  margin-top: -76px;
}

.quote-left {
  margin-left: 39px;
}

.quote-left-1 {
  margin-left: 25px;
  margin-top: -59px;
}

.quote-right-2 {
  float: right;
  margin-right: 59px;
  margin-top: -59px;
}

.quote-right-3 {
  position: relative;
  left: 24px;
  bottom: 59px;
}

.testimonial-card > p {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #373737;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 24px;
  margin-left: 71px;
}

.rectangle-image {
  text-align: right;
  margin-right: 140px;
  margin-top: 4px;
}

.author-name {
  text-align: right;
  margin-right: 30px;
  margin-top: -14px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #090909;
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
  position: initial;
}
