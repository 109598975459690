.contact-info-container {
  display: flex;
  justify-content: center;
  margin: 30px;
  margin-bottom: 100px;
  /* background-color: #fff; */
}
.contact-info-body {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* flex-direction: column; */
}

.contact-info-details-container {
  background: #f5a023;
  padding: 45px;
  flex: 1;
}
.contact-info-form-container {
  padding: 45px;
  flex: 1;
}
.contact-info-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  margin-bottom: 20px;
  color: #090909;
}

.contact-info-info-container {
  display: flex;
  margin: 24px;
}

.contact-info-info-icon {
  margin-right: 24px;
}
.contact-info-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Sec */

  color: #090909;
}
.contact-info-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  /* or 23px */

  letter-spacing: 0.05em;

  /* Sec */

  color: #090909;
}
.contact-info-form-input-container {
  padding: 10px 0;
}

.contact-info-form-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Character / Title .85 */

  color: rgba(0, 0, 0, 0.85);
  /* margin-bottom: 5px; */
}

.contact-info-form-input {
  padding: 8px 12px;

  width: 95%;
  height: 30px;

  /* Neutral/1 */

  background: #ffffff;
  border: 1px solid rgba(9, 9, 9, 0.3);
  border-radius: 2px;
}

.contact-info-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  gap: 11px;
  margin-top: 50px;
  flex: 1;
  cursor: pointer;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  /* Primary */

  background: #f5a023;
  border-radius: 4px;
  border: none;
}
.contact-info-form-input input {
  border: none;
  outline: none;
  border-left: 1px solid #ccc;
  margin-left: 10px;
}
@media (max-width: 950px) {
  .contact-info-body {
    flex-direction: column;
  }
}

.contact-info-sub-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #353535;
  margin-bottom: 17px;
  opacity: 0.8;
}
