.property-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 60px;
  /* margin: 100px 274px 10px 301px; */
  /* max-width: 300px; */
}
.proparty-card {
  margin: 10px;
  max-width: 220px;
}

.proparty-card > p {
  /* font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212325;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 27px;
  margin-top: 9px; */
  margin-left: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #212325;

  opacity: 0.6;
}
.property-content {
  /* font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #212325;
  font-family: Arial, Helvetica, sans-serif;*/
  margin-left: 7px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  /* primary */

  color: #212325;
}
.property-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #212325;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 7px;
}
.project-card-button {
  background: #f5a023;
  list-style: none;
  border: 0.817073px solid #f5a023;
  border-radius: 4px;
  width: 112px;
  height: 35px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #212325;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 20px;
  margin-left: 7px;
  cursor: pointer;
}
