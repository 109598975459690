.services {
  background-color: #fdfcfa;
}
.services-container {
  max-width: 1440px;
  margin: auto;
  padding-bottom: 30px;
}
.services-header {
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
  margin-bottom: 70px;
  padding-top: 60px;
}

.service-card-main-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 141.7%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}
.service-card-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 141.7%;
  text-align: center;
  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.4);
}

.service-card-container {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
  margin: 10px;
  max-width: 300px;
  min-width: 300px;

  /* height: 219px; */

  background: #ffffff;
  /* card */
  box-shadow: 0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #d0daf4;
  border-radius: 14px;
}
.slick-slide img {
  display: block;
  margin-bottom: 27px !important;
}

.slider-service-card-holder {
  display: flex;
  justify-content: center;
}

@media (max-width: 450px) {
  .service-card-container {
    transform: scale(0.7);
  }
}

/* */
