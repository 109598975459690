.about-us {
  background: #fdfcfa;
}
.about-us-container {
  display: flex;
  background: #fdfcfa;
  max-width: 1440px;
  margin: 70px;
  background-image: url("https://baladi.rayabharitechnologies.com/img/group.png");
}
.about-us-container-2 {
  display: flex;
  background: #fdfcfa;
  max-width: 1440px;


  margin: 70;
  background-image: url("https://baladi.rayabharitechnologies.com/img/group2.png");
}
.about-us-section {
  background: #fdfcfa;
  margin-bottom: 30px;
}

.about-us-section-2 {
  flex: 1;
  margin-left: 50px;
  min-height: 520px;
}
.about-us-section-2-2 {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 50px;
  /* min-height: 520px; */
}
.about-us-section-1 {
  flex: 1;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.about-us-section-1-2 {
  flex: 0.7;
  display: flex;
  justify-content: center;
}
.about-us-content {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #212325;
  opacity: 0.8;
  margin-top: 28px;
}
.about-us-button {
  /* Frame 163 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 11px;

  width: 190px;
  height: 45px;

  /* Primary */
  background: #f5a023;
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  /* Make Schedule to talk */

  width: 150px;
  height: 17px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Sec */
  color: #090909;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 65px;
}
.about-us-main-header {
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
  margin-bottom: 46px;
  padding-top: 60px;
  /* text-align: left; */
}
.about-us-header {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
  margin-bottom: 46px;
  padding-top: 60px;
  text-align: center;
}
.about-us-sub-header {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #212325;
}

@media (max-width: 850px) {
  .about-us-header {
    text-align: center;
  }
  .about-us-container {
    flex-direction: column;
    max-height: 934px;
  }
  .about-us-container-2 {
    flex-direction: column;
    background-image: none;
  }
  .about-us-content {
    text-align: center;
  }
  
 
  .about-us-section-3 {
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    background-image: url("https://baladi.rayabharitechnologies.com/img/group2.png");
  }
  .about-us-section-2 {
    transform: scale(0.5);
    position: relative;
    top: -200px;
    /* left: -20px; */
  }
  .about-us-section-2 {
    display: none;
  }
  .about-us-button {
    margin: auto;

    margin-top: 25px;
    padding: 14px 60px;
  }
}

.work-in-progress-container {
  padding: 100px;
  /* padding-bottom: 70px; */
}

.work-in-progress-cards-container {
  margin-left: 40px;
}
.about-us-container-1-image {
  display: none;
}
@media (max-width: 900px) {
  .work-in-progress-container {
    flex-direction: column;
  }
  .work-in-progress-cards-container {
    margin-top: 40px;
  }
  
  .work-in-progress-content-container {
    text-align: center;
  }
}

@media (max-width: 900px) {
  .about-us-section-2 {
    /* transform: scale(0.5);
    position: relative;
    top: -200px; */
    left: -20px;
  }
  .about-us-section-2 {
    display: none;
  }
  .about-us-button {
    margin: auto !important;
    margin-top: 30px !important;
    text-align: center;
  }
  .work-in-progress-container {
    padding: 20px;
  }
  .work-in-progress-cards-container {
    margin-left: 5px;
  }
  .about-us-container {
    background-image: none;
  }
  .about-us-container-1-image {
    display: block;
    background-image: url("http://baladi.rayabharitechnologies.com/img/group.png");
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
  }
  .about-us-content {
    margin-left: 30px;
    margin-right: 30px;
  }
  .about-us-section-1 {
    text-align: center;
  }
  
}

@media (max-width: 1440px) {
  .about-us-section-1-container {
    /* padding: 30px; */
  }
  .about-us-content {
    padding-left: 30px;
  }
  .about-us-sub-header {
    margin-left: 27px;
  }

  .about-us-button {
    margin-left: 27px;
  }
  
}
@media (max-width:1125){
  .about-us-section-2-2 {
    display: block !important;
    flex: 1;
    justify-content: center;
    margin-left: 50px;
    /* min-height: 520px; */
  }

}

