.project-details-container {
  max-width: 1440px;
  margin: auto;
}

.project-details-content-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 41.2734px;
  line-height: 15px;
  color: black;
  /* margin: 30px 50px; */
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
}

.project-details-sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 10px;
  color: #212325;
  opacity: 0.6;
  position: relative;
  top: -10px;
}

.map-icon {
  width: 25px;
  height: 30px;
}

.project-details-box {
  display: flex;
  height: 400px;
}

.project-details-block1 {
  flex-direction: column;
  width: 700px;
}

.block1-image {
  width: 100%;
  height: auto;
}

.block2-images {
  width: 130px;
  height: 130px;
  margin-left: 8px;
}

.home-content-block2 {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

/* Mobile view responsive  */
@media (min-width: 1024px) {
  .home-container {
    width: 100%;
  }
}

.project-details-main-image {
  display: flex;
  flex: 1;
  overflow: hidden;
  border-radius: 15px;
  background-color: #212325;
  margin: 5px;
  cursor: pointer;
}

.project-details-main-images-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  cursor: pointer;
  /* justify-content: center; */
}

.property-details-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 37px;
  letter-spacing: 0.04em;
  color: #212325;
}

.project-dettails-details-section-container {
  padding: 40px;
  border: 1px solid #f5a023;
  border-radius: 10px;
  background: #fff;
  margin-top: 40px;
}

@media (min-width: 1024px) {
  .project-details-container {
    margin: 40px;
  }
}
